import LocalMovesIcon from '@mui/icons-material/MapsHomeWork'
import MapIcon from '@mui/icons-material/Map'
import CaUsMovesIcon from '@mui/icons-material/Public'
import ItemDeliveryIcon from '@mui/icons-material/Luggage'

import { Service } from './Service.enum'
import { type Icon } from '../../../../common/icon/Icon.type'

export const serviceIcons: Record<Service, Icon> = {
  [Service.LocalMoves]: LocalMovesIcon,
  [Service.LongDistanceMoves]: MapIcon,
  [Service.CaUsMoves]: CaUsMovesIcon,
  [Service.ItemDelivery]: ItemDeliveryIcon,
  [Service.UsInterstate]: MapIcon,
}
