import { type Amount } from '../../../common/amount'
import { type FullMove } from '../../adminMoves'
import { type PromoCode } from '../../adminPromoCodes'
import { type TaxBreakdown } from '../../taxes'

export enum ChargeUnit {
  hour = 'hour',
  km = 'km',
  mile = 'mile',
}

export enum ChargeCategory {
  labour = 'labour',
  tripAndTravel = 'tripAndTravel',
  additionalCharges = 'additionalCharges',
  protectiveMaterial = 'protectiveMaterial',
}

export enum ChargeDescription {
  movingLabour = 'movingLabour',
  packingLabour = 'packingLabour',
  travelLabour = 'travelLabour',
  transportLabour = 'transportLabour',

  travelTruckFeePerKilometer = 'travelTruckFeePerKilometer',
  transportTruckFeePerKilometer = 'transportTruckFeePerKilometer',
  fixedTruckFee = 'fixedTruckFee',

  additionalChargesOvernight = 'additionalChargesOvernight',
  additionalChargesStairs = 'additionalChargesStairs',
  additionalChargesSpecialServices = 'additionalChargesSpecialServices',
  additionalChargesSpecialItems = 'additionalChargesSpecialItems',

  protectiveMaterialProduct = 'protectiveMaterialProduct',

  customCharge = 'customCharge',
  customChargeMw = 'customChargeMw',
}

export enum InvoiceCategory {
  move = 'move',
  adjustment = 'adjustment',
}

export type Charge = {
  pricingCategory: ChargeCategory
  description: ChargeDescription
  overrideDescription?: string
  item?: string
  quantity: number
  unit?: ChargeUnit
  unitPrice: Amount
  subtotal: Amount
  taxable?: boolean
}

export type ChargeWithoutSubtotal = Omit<Charge, 'subtotal'>

export type InvoicePayload = {
  category: InvoiceCategory
  details?: ChargeWithoutSubtotal[]
  promoCode?: PromoCode
  tip?: Amount
  autoCapture?: boolean
  companyBranchId?: string
}

export type Invoice = {
  id: string
  invoiceNumber: string
  invoiceDate: Date
  voided: boolean
  createdAt: Date
  category: InvoiceCategory
  details: Charge[]
  subtotal: Amount
  discount?: Amount
  taxes?: TaxBreakdown[]
  promoCode?: PromoCode
  tip?: Amount
  total: Amount
}

export type FullInvoiceEstimate = Pick<Invoice, 'details' | 'subtotal' | 'discount' | 'taxes' | 'tip' | 'total'>

export type InvoiceEstimate = Omit<FullInvoiceEstimate, 'details'>

export type CalculateInvoicePayload = {
  details: ChargeWithoutSubtotal[]
  promoCode?: PromoCode
  tip?: Amount
}

export type CreateInvoiceResponse = {
  move: FullMove
  paymentCaptured: boolean
  paymentError?: string
}
