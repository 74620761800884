import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import RemoveIcon from '@mui/icons-material/CancelOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {
  chargeCategoryChild,
  type ChargeDescription,
} from '../../../../../modules/invoices'
import { type Currency } from '../../../../../common/amount'
import { formatPrice } from '../../../../../utils/money'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import { Select, Input, SingleCheckbox } from '../../../../form'
import { type RowType } from './ChargeRow.enum'
import {
  getDefaultValues,
  getRowType,
  isEditable,
  showTaxable,
  useItemOptions,
  useUnits,
} from './ChargeRow.utils'
import { type MoveSize } from '../ChargesFormContent.type'
import { getRowSubtotal } from '../ChargesFormContent.utils'
import InfoTooltip from '../../../../InfoTooltip'

type ChargeRowProps = {
  index: number
  moveSize?: MoveSize
  currency: Currency
  onDelete?: () => void
  onCategoryChange: () => void
  autoFocus?: boolean
  editAll?: boolean
  originCountry?: string
}

const ChargeRow: React.FC<ChargeRowProps> = ({
  index,
  moveSize,
  currency,
  onDelete,
  onCategoryChange,
  autoFocus = false,
  editAll = false,
  originCountry,
}) => {
  const { t } = useTranslation()
  const currentRowType = useRef<RowType | null>(null)
  const currentCategory = useRef<string | null>(null)
  const [hasRowFocus, setHasRowFocus] = useState(false)

  const namePrefix = 'charges'
  const name = `${namePrefix}.${index}`
  const values = useWatch({ name })
  const form = useFormContext()
  const description = values.categoryAndDescription.split('.')[1] as ChargeDescription
  const rowType = getRowType(description)
  const itemOptions = useItemOptions(rowType)
  const units = useUnits(rowType, originCountry)
  const subtotal = getRowSubtotal(values)
  const editable = editAll || isEditable(description)
  const shouldShowTaxable = currentRowType.current ? showTaxable(currentRowType.current) : false

  const unitOptions = units.length > 1 ? units : undefined
  const unitAdornment = units.length === 1 ? units[0].label : undefined
  const shouldFocusOnDescription = !itemOptions && !values.item

  /**
   * reset row when row type is changed
   */
  useEffect(() => {
    if (currentRowType.current === null) {
      currentRowType.current = rowType
      return
    }
    if (currentRowType.current === rowType) {
      return
    }
    currentRowType.current = rowType

    const defaultValues = getDefaultValues(moveSize, rowType)
    form.setValue(name, {
      categoryAndDescription: values.categoryAndDescription,
      ...defaultValues,
    }, { shouldValidate: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowType])

  /**
   * notify parent form when category/description is updated to reorder fields properly
   */
  useEffect(() => {
    if (currentCategory.current === null) {
      currentCategory.current = values.categoryAndDescription
      return
    }
    if (currentCategory.current === values.categoryAndDescription) {
      currentCategory.current = values.categoryAndDescription
      return
    }

    onCategoryChange()
    form.clearErrors(name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryAndDescription])

  const pricingDescriptionOptions = Object.entries(chargeCategoryChild).map(([category, descriptions]) => ({
    group: t(`quotes.pricing.categories.${category}`),
    options: descriptions
      .filter((description) => editAll || !editable || isEditable(description))
      .map((description) => ({
        value: `${category}.${description}`,
        label: (
          <div className="flex gap-x-1">
            <div className="category flex items-center gap-x-1 text-gray-500">
              { t(`quotes.pricing.categories.${category}`) }
              <ChevronRightIcon className="!text-[18px] text-gray-400" />
            </div>
            { t(`quotes.pricing.description.${description}`) }
          </div>
        ),
        props: { className: '!ml-8 [&_.category]:hidden' },
      })),
    props: { className: '!font-bold !text-black' },
  }))

  return (
    <tr className={mergeClassName(
      'align-top',
      hasRowFocus && 'bg-sky-100/60',
    )}
    >
      <td className="w-[475px] p-1">
        <div className="flex gap-x-2">
          <Select
            name={`charges.${index}.categoryAndDescription`}
            options={pricingDescriptionOptions}
            size="small"
            onFocus={() => { setHasRowFocus(true) }}
            onBlur={() => { setHasRowFocus(false) }}
            disabled={!editAll}
            className={mergeClassName(
              'bg-white',
              !editAll && 'bg-neutral-100',
            )}
          />

          { values.overrideDescription && (
            <div className="pt-[5px]">
              <InfoTooltip tooltip={values.overrideDescription} />
            </div>
          ) }
        </div>
      </td>

      <td className="w-[375px] p-1">
        { itemOptions
          ? (
            <Select
              name={`${namePrefix}.${index}.item`}
              size="small"
              options={itemOptions}
              onFocus={() => { setHasRowFocus(true) }}
              onBlur={() => { setHasRowFocus(false) }}
              disabled={!editable}
              className={mergeClassName(
                'bg-white',
                !editable && 'bg-neutral-100',
              )}
            />
            )
          : (
            <Input
              name={`${namePrefix}.${index}.item`}
              autoFocus={autoFocus && shouldFocusOnDescription}
              size="small"
              placeholder={t('forms.invoice.charges.enterDescription')}
              onFocus={() => { setHasRowFocus(true) }}
              onBlur={() => { setHasRowFocus(false) }}
              disabled={!editable}
              InputProps={{
                className: mergeClassName(
                  'bg-white',
                  !editable && 'bg-neutral-100',
                ),
              }}
            />
            ) }
      </td>

      <td className="w-[180px] min-w-[180px] p-1">
        <div className={mergeClassName(
          'flex w-full',
          editable && '[&_fieldset]:hover:!border-black',
        )}
        >
          <div className="grow">
            <Input
              name={`${namePrefix}.${index}.quantity`}
              autoFocus={autoFocus && !shouldFocusOnDescription}
              InputProps={{
                endAdornment: unitAdornment
                  ? (
                    <InputAdornment position="end">
                      { unitAdornment }
                    </InputAdornment>
                    )
                  : undefined,
                className: mergeClassName(
                  'bg-white',
                  unitOptions && '!rounded-r-none child:!border-r-0',
                  !editable && 'bg-neutral-100',
                ),
              }}
              inputProps={{
                maxLength: 5,
              }}
              size="small"
              onFocus={() => { setHasRowFocus(true) }}
              onBlur={() => { setHasRowFocus(false) }}
              disabled={!editable}
            />
          </div>

          { unitOptions && (
            <div>
              <Select
                name={`${namePrefix}.${index}.unit`}
                size="small"
                onFocus={() => { setHasRowFocus(true) }}
                onBlur={() => { setHasRowFocus(false) }}
                options={unitOptions as any[]}
                className="bg-white child:!rounded-l-none child:!border-l-0"
              />
            </div>
          ) }
        </div>
      </td>

      <td className="w-[150px] min-w-[150px] p-1">
        <Input
          name={`${namePrefix}.${index}.unitPrice`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                $
              </InputAdornment>
            ),
            className: 'bg-white',
          }}
          inputProps={{
            maxLength: 8,
          }}
          size="small"
          onFocus={() => { setHasRowFocus(true) }}
          onBlur={() => { setHasRowFocus(false) }}
        />
      </td>

      <td className="w-[30px]">
        <div className="-mr-3 pl-3 pt-px">
          { shouldShowTaxable && (
            <SingleCheckbox
              name={`${namePrefix}.${index}.taxable`}
            />
          ) }
        </div>
      </td>

      <td className="whitespace-nowrap px-4 pt-3 text-right text-neutral-400">
        { formatPrice({
          price: subtotal,
          currency,
        }) }
      </td>

      <td className="p-1">
        { onDelete && editable && (
          <IconButton
            color="error"
            onClick={onDelete}
          >
            <RemoveIcon />
          </IconButton>
        ) }
      </td>
    </tr>

  )
}

export default ChargeRow
