const ONE_KM_IN_MILE = 0.6213711922

export const convertKmsToMiles = (kms: number, nbDecimals = 1) => {
  return round(kms * ONE_KM_IN_MILE, nbDecimals)
}
export const convertMilesToKms = (miles: number, nbDecimals = 1) => {
  return round(miles / ONE_KM_IN_MILE, nbDecimals)
}

/**
 * round value to the desired number of decimals
 */
const round = (number: number, nbDecimals: number) => {
  const base = Math.pow(10, nbDecimals)
  return Math.round(number * base) / base
}
