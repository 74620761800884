import { v4 as uuid } from 'uuid'

export const DEFAULT_MAX_PICKUP_RADIUS = 50
export const DEFAULT_MAX_PICKUP_RADIUS_MILES = 30
export const DEFAULT_MAX_DISTANCE = 500
export const DEFAULT_MAX_DISTANCE_MILES = 300
export const MIN_PICKUP_RADIUS = 5
export const MAX_PICKUP_RADIUS = 1000
export const MIN_DISTANCE = 30
export const MAX_RULES = 5

const getDefaultRules = (us = false) => {
  const rule1To = us ? '60' : '100'
  const rule2To = us ? DEFAULT_MAX_DISTANCE_MILES : DEFAULT_MAX_DISTANCE
  const defaultPrice = us ? '1.20' : '0.75'

  return [
    {
      id: uuid(),
      to: rule1To,
      travel: {
        chargeLabour: true,
        minLabourTime: '1',
        pricePerKm: { basePrice: '' },
      },
      transport: {
        chargeLabour: true,
        pricePerKm: { basePrice: '' },
      },
      chargeTravelKmFee: false,
      chargeTransportKmFee: false,
      chargeFixedTruckFee: false,
    },
    {
      id: uuid(),
      to: String(rule2To),
      travel: {
        chargeLabour: true,
        pricePerKm: { basePrice: defaultPrice },
      },
      transport: {
        chargeLabour: true,
        pricePerKm: { basePrice: defaultPrice },
      },
      chargeTravelKmFee: true,
      chargeTransportKmFee: true,
      chargeFixedTruckFee: false,
    },
  ]
}

export const DEFAULT_RULES = getDefaultRules()
export const DEFAULT_RULES_US = getDefaultRules(true)
