import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import MapIcon from '@mui/icons-material/MapOutlined'
import HomeIcon from '@mui/icons-material/HomeWorkOutlined'

import { type FullMove } from '../../../modules/adminMoves'
import { type FullMoveForPartner, useMoveState } from '../../../modules/moves'
import { type ArrivalWindow } from '../../../modules/companies'
import { formatNumber } from '../../../utils/number'
import { useDateFormatter } from '../../../utils/date'
import { useTimeFormatter } from '../../../utils/time'
import { capitalizeFirstLetter } from '../../../utils/string'
import {
  BooleanValue,
  Cell,
  Label,
  Quantities,
  Row,
  Value,
  Values,
} from '../../cell'
import LocationRow from './LocationRow'
import { convertKmsToMiles } from '../../../utils/conversion'

type JobDetailsProps = {
  move: FullMove | FullMoveForPartner
  date?: Date
  time?: ArrivalWindow
}

const JobDetails: React.FC<JobDetailsProps> = ({
  move,
  date,
  time,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const { formatSeconds } = useTimeFormatter()
  const {
    selectedQuote,
    isAcceptedByCustomer,
    moveDate,
    useMiles,
  } = useMoveState().forMove(move)

  return (
    <>
      <div className="mb-4 w-full rounded-md bg-slate-600 px-4 pb-4 text-white lg:w-3/4">
        <Row>
          <Cell>
            <Label className="text-gray-300">
              { t('move.jobs.job.moveContent.volumeAndWeight') }
            </Label>
            <Value>
              { formatNumber(move.volumeCubicFeet) } { t('units.cubicFeet') }
              <br />
              { formatNumber(move.weightPounds) } { t('units.pounds') }
            </Value>
          </Cell>

          <Cell>
            <Label className="text-gray-300">
              { t('move.jobs.job.moveContent.estimatedRequirements') }
            </Label>
            <Value>
              { t('nbMen', { count: move.nbMen }) }
              <br />

              <Quantities>
                { move.nbTrucks.map(({ key, value }) => ({
                  key: t(`trucks.${key}.title`, { count: value }),
                  value: `${value} x`,
                })) }
              </Quantities>
            </Value>
          </Cell>

          <Cell>
            <Label className="text-gray-300">
              { t('move.jobs.job.moveContent.estimatedLabour') }
            </Label>
            <Value>
              <div>
                <span className="text-sm text-neutral-400">
                  { t('move.jobs.job.moveContent.moving') }
                  { ': ' }
                </span>
                { move.estimatedMovingLabourTime }
                { t('units.hour') }
              </div>
              { !!move.estimatedPackingLabourTime && (
                <div>
                  <span className="text-sm text-neutral-400">
                    { t('move.jobs.job.moveContent.packing') }
                    { ': ' }
                  </span>
                  { move.estimatedPackingLabourTime }
                  { t('units.hour') }
                </div>
              ) }
            </Value>
          </Cell>
        </Row>
      </div>

      <Row>
        <Cell>
          <Label>
            { (selectedQuote ?? date)
              ? t('move.jobs.job.moveDetails.selectedDate')
              : t('move.jobs.job.moveDetails.movingDate') }
          </Label>
          <Value>

            { formatDate(date ?? moveDate) }

            { 'flexibleDate' in move && (
              <div className="mt-2">
                <BooleanValue
                  value={move.flexibleDate}
                  labelTrue={t('move.jobs.job.moveDetails.flexibleDate')}
                  labelFalse={t('move.jobs.job.moveDetails.dateNotFlexible')}
                />
              </div>
            ) }

          </Value>
        </Cell>

        <Cell>
          <Label>
            { (isAcceptedByCustomer || !!time)
              ? t('move.jobs.job.moveDetails.selectedTime')
              : t('move.jobs.job.moveDetails.preferredTime') }
          </Label>

          { move.quoteAccepted?.movingTime
            ? (
              <Value>
                { t(`arrivalWindows.${time ?? move.quoteAccepted.movingTime}.title`) }
              </Value>
              )
            : (
              <Values>
                { move.preferredTime?.map(preferredTime => t(`arrivalWindows.${preferredTime}.title`)) }
              </Values>
              ) }

        </Cell>

        <Cell>
          <Label>
            { t('move.jobs.job.moveDetails.moveDistance') }
            { ' ' }
            <span className="text-sm font-normal">(
              { t('move.jobs.job.moveDetails.originToDestination') }
              )
            </span>
          </Label>
          { move.moveLeg && (
            <Value>
              { useMiles
                ? (
                  <>
                    { convertKmsToMiles(move.moveLeg.distanceMeters / 1000) } { t('units.mile') }
                  </>
                  )
                : (
                  <>
                    { (move.moveLeg.distanceMeters / 1000).toFixed(1) } { t('units.km') }
                  </>
                  ) }

              { ' ' }
              ({ t('time.timeWithTraffic', {
                time: formatSeconds(
                  move.moveLeg.durationInTrafficSeconds ?? move.moveLeg.durationSeconds,
                ),
              }) })
            </Value>
          ) }
        </Cell>

        <Cell>
          { move.moveDurationSeconds && (
            <>
              <Label>
                { t('move.jobs.job.moveDetails.totalMoveDuration') }
              </Label>
              { move.moveLeg && (
                <Value>
                  { formatSeconds(move.moveDurationSeconds) }
                </Value>
              ) }
            </>
          ) }
        </Cell>

      </Row>

      <h2 className="my-6 font-body2 text-2xl font-semibold text-slate-700 lg:mt-12">
        <MapIcon />
        { ' ' }
        { t('move.jobs.job.locations.title') }
      </h2>

      <div className="flex flex-col gap-y-4 lg:my-4">

        { ['origin', 'destination'].map((address) => {
          const isOrigin = address === 'origin'
          const location = isOrigin ? move.origin : move.destination
          const title = isOrigin
            ? t('move.jobs.job.locations.origin')
            : t('move.jobs.job.locations.destination')

          return (
            <Fragment key={address}>
              <LocationRow
                label={title}
                location={location}
              />
              { isOrigin && move.stops?.map((stop, index) => (
                <LocationRow
                      // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={(
                    <>
                      { capitalizeFirstLetter(stop.description) }
                      <div className="text-sm text-neutral-400">
                        { stop.stopDuration } { t('units.hour') }
                      </div>
                    </>
                  )}
                  location={stop}
                />
              )) }
            </Fragment>
          )
        }) }
      </div>

      <h2 className="mb-4 mt-6 font-body2 text-2xl font-semibold text-slate-700 lg:mt-12">
        <HomeIcon />
        { ' ' }
        { t('move.jobs.job.moveContent.title') }
      </h2>

      <Row>
        <Cell>
          <Label>
            { t('residenceType.title') }
          </Label>
          <Value>
            { t(`residenceType.${move.residenceType}.title`) }
          </Value>
        </Cell>

        <Cell>
          <Label>
            { t('nbBoxes.title') }
          </Label>
          <Value>
            { move.nbBoxes
              ? (
                <>{ t(`nbBoxes.${move.nbBoxes}.title`) }
                  { ' ' }
                  <small>({ t('move.jobs.job.moveContent.approximately') })</small>
                </>
                )
              : (
                <div className="mt-1">
                  <BooleanValue
                    value={false}
                    labelFalse={t('nbBoxes.0.title')}
                  />
                </div>
                ) }
          </Value>

          <Label className="pt-2">
            { t('furnitureRatio.title') }
          </Label>
          <Value>
            { move.furnitureRatio
              ? t(`furnitureRatio.${move.furnitureRatio}.title`)
              : (
                <div className="mt-1">
                  <BooleanValue
                    value={false}
                    labelFalse={t('furnitureRatio.0.title')}
                  />
                </div>
                ) }
          </Value>
        </Cell>

        <Cell className="lg:ml-4 lg:mr-8 lg:flex-[2_2_0%] lg:pl-0">
          <Label>
            { t('rooms.title') }
          </Label>
          <Quantities>

            { move.residenceRooms?.map(({ key, value }) => ({
              key: t(`rooms.${key}.title`, { count: value }),
              value,
            })) }

          </Quantities>
        </Cell>

      </Row>

      <Row>
        <Cell>
          <Label>
            { t('specialServices.title') }
          </Label>

          { (move.specialItems?.length ?? 0) > 0
            ? (
              <Quantities>
                { move.specialServices?.map(({ key, value }) => ({
                  key: t(`specialServices.${key}.title`, { count: value }),
                  value,
                })) }
              </Quantities>
              )
            : (
              <div className="mt-1">
                <BooleanValue
                  value={false}
                  labelFalse={t('no')}
                />
              </div>
              ) }

        </Cell>

        <Cell>
          <Label>
            { t('specialItems.title') }
          </Label>

          { (move.specialItems?.length ?? 0) > 0
            ? (
              <Quantities>
                { move.specialItems?.map(({ key, value }) => ({
                  key: t(`specialItems.${key}.title`, { count: value }),
                  value,
                })) }
              </Quantities>
              )
            : (
              <div className="mt-1">
                <BooleanValue
                  value={false}
                  labelFalse={t('no')}
                />
              </div>
              ) }

        </Cell>
      </Row>
    </>
  )
}

export default JobDetails
