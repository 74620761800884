import { useTranslation } from 'react-i18next'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import ArrowIcon from '@mui/icons-material/ArrowForward'

import { type LightJobRequest } from '../../../modules/jobRequests'
import { capitalizeFirstLetter } from '../../../utils/string'
import { formatNumber } from '../../../utils/number'
import { convertKmsToMiles } from '../../../utils/conversion'
import { useCurrentCompanyBranch } from '../../../modules/companies'

const ONE_KILOMETER_METERS = 1000

type AdressesProps = {
  jobRequest: LightJobRequest
}

const Adresses: React.FC<AdressesProps> = ({ jobRequest }) => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const usCompany = currentCompanyBranch?.tripAndTravel?.trucksOriginAddress?.country?.toUpperCase() === 'US'
  const useMiles = usCompany

  const totalDistanceKilometers = formatNumber(parseFloat((jobRequest.totalDistance / ONE_KILOMETER_METERS).toFixed(1)))
  const totalDistanceMiles = convertKmsToMiles(jobRequest.totalDistance / ONE_KILOMETER_METERS)

  return (
    <div className="flex justify-center gap-2 text-center lg:grow lg:justify-start lg:text-left">
      <MarkerIcon className="mt-1 !hidden !text-[18px] text-neutral-400 lg:!block" />
      <div>
        <div className="flex flex-wrap items-center justify-center gap-x-2 text-lg text-neutral-500 lg:justify-start">
          <MarkerIcon className="mt-1 !text-[18px] text-neutral-400 lg:!hidden" />
          <span>
            { capitalizeFirstLetter(jobRequest.originCity) }
            { ', ' }
            { jobRequest.originRegion.toUpperCase() }
          </span>
          <ArrowIcon className="!text-[18px] text-neutral-300" />
          <span>
            { capitalizeFirstLetter(jobRequest.destinationCity) }
            { ', ' }
            { jobRequest.destinationRegion.toUpperCase() }
          </span>
        </div>

        <div className="text-sm text-neutral-400">
          <span className="text-neutral-500">
            { useMiles
              ? (
                <>
                  { totalDistanceMiles }
                  { ' ' }
                  { t('units.mile') }
                </>
                )
              : (
                <>
                  { totalDistanceKilometers }
                  { ' ' }
                  { t('units.km') }
                </>
                ) }

          </span>
          { ' ' }
          ({ t('jobRequest.totalDistance') })
        </div>
      </div>
    </div>
  )
}

export default Adresses
