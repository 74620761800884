import { useEffect, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import RemoveIcon from '@mui/icons-material/CancelOutlined'
import MarkerIcon from '@mui/icons-material/RoomOutlined'

import { type CompanyBranch, type Truck } from '../../../../modules/companies'
import { Input, SingleCheckbox } from '../../../form'
import Paper from '../../../Paper/Paper'
import PricesInput from '../../../companyBranch/PricesInput'
import { type TripAndTravelFormValues } from './TripAndTravel.type'
import { DEFAULT_RULES, MAX_RULES, MIN_DISTANCE } from './TripAndTravel.const'
import { keyValueMapToRecord } from '../../../../common/keyValueMap'
import { type Currency } from '../../../../common/amount'
import { getCurrencyDetails } from '../../../../utils/money/getCurrencyDetails'

type PricingRulesFormProps = {
  companyBranch?: CompanyBranch
  currency: Currency
  useMiles?: boolean
}

const PricingRulesForm: React.FC<PricingRulesFormProps> = ({
  companyBranch,
  currency,
  useMiles = false,
}) => {
  const { t } = useTranslation()
  const form = useFormContext()
  const rules = useWatch<TripAndTravelFormValues, 'rules'>({ name: 'rules' })
  const maxDistanceValue = useWatch<TripAndTravelFormValues, 'maxDistance'>({ name: 'maxDistance' })
  const maxDistanceValueValid = /^[0-9]+$/.test(maxDistanceValue) && parseInt(maxDistanceValue) >= MIN_DISTANCE
  const maxDistance = maxDistanceValueValid ? maxDistanceValue : undefined
  const distanceUnitLabel = useMiles ? t('units.mile') : t('units.km')

  const { symbol: currencySymbol } = getCurrencyDetails(currency)

  const nbRules = rules.length ?? 0
  const availableTrucks = Object.keys(keyValueMapToRecord(companyBranch?.services?.nbTrucks)) as Truck[]

  availableTrucks.sort((a, b) => {
    const lengths = [a, b].map(truck => parseInt(truck.replace(/[a-z]/gi, '')))
    return lengths[0] - lengths[1]
  })

  const getPreviousStepValue = (index: number) => {
    if (index === 0) {
      return '0'
    }
    const max = maxDistance ? parseInt(maxDistance) : undefined
    const previousValue = rules[index - 1]?.to ? parseInt(rules[index - 1].to) : undefined
    if (!previousValue || !max || previousValue >= (max - 1)) {
      return '-'
    }
    return String(previousValue + 1)
  }

  const getSplitValue = () => {
    const max = maxDistance ? parseInt(maxDistance) : undefined
    if (!max) {
      return ''
    }

    if (nbRules === 1) {
      return String(max / 2)
    }

    const refValue = rules[nbRules - 2]?.to ? parseInt(rules[nbRules - 2]?.to) : undefined
    if (!refValue || refValue >= (max - 1)) {
      return ''
    }

    const splitValue = Math.ceil((max - refValue) / 2) + refValue
    if (splitValue <= (refValue + 1) || splitValue >= (max - 1)) {
      return ''
    }
    return String(splitValue)
  }

  const addRule = () => {
    const existingRules = [...rules]
    const lastItem = existingRules.pop()

    const newRules = [
      ...existingRules,
      {
        ...DEFAULT_RULES[0],
        id: uuid(),
        to: getSplitValue(),
      },
      lastItem,
    ]
    form.setValue('rules', newRules)
    onRangeUpdate()
  }

  const removeRule = (index: number) => {
    const newRules = [...rules]
    newRules.splice(index, 1)
    form.setValue('rules', newRules)
    onRangeUpdate()
  }

  const onRangeUpdate = useCallback(() => {
    const fields = Array.from({ length: nbRules - 1 }).map((_, index) => `rules.${index}.to`)
    if (fields.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      form.trigger(fields)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbRules])

  useEffect(() => {
    if (!maxDistance) {
      return
    }
    onRangeUpdate()
  }, [maxDistance, onRangeUpdate])

  const clearFieldWhenDisabled = (field: string, checked: boolean) => {
    if (checked) {
      return
    }
    form.unregister(field, {
      keepValue: false,
      keepDefaultValue: true,
      keepError: false,
    })
  }

  return (
    <>
      { rules.map(({ id }, index: number) => {
        const isLast = index === nbRules - 1
        const previousValue = getPreviousStepValue(index)

        return (
          <div key={id}>
            { isLast && (
              <div className="mb-6">
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addRule}
                  disabled={nbRules >= MAX_RULES}
                >
                  { t('forms.companyBranch.tripAndTravel.pricingRules.addRule') }
                </Button>
              </div>
            ) }

            <h3 className="mb-3 flex gap-2 font-body2 text-[1.4rem] font-light leading-7">
              <div><MarkerIcon className="mt-1 text-gray-400" /></div>
              <div className="items-center gap-2">
                <span className="pr-1">
                  { t('forms.companyBranch.tripAndTravel.pricingRules.from') }
                </span>
                <span className="px-1 font-bold">{ previousValue }</span>
                <span className="px-1">{ t('forms.companyBranch.tripAndTravel.pricingRules.to') }</span>
                { isLast && <span className="px-1 font-bold">{ maxDistance ?? '-' }</span> }
                { !isLast && (
                  <Input
                    name={`rules.${index}.to`}
                    fullWidth={false}
                    size="small"
                    className="w-[100px]"
                    margin="none"
                    onBlur={onRangeUpdate}
                  />
                ) }
                <span className="px-1">
                  { distanceUnitLabel }
                  { !isLast && (
                    <IconButton
                      color="error"
                      onClick={() => { removeRule(index) }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  ) }
                </span>
              </div>
            </h3>
            <Paper>

              <h4 className="mb-3 font-body2 text-[1.2rem] font-light leading-7">
                <Trans i18nKey="forms.companyBranch.tripAndTravel.pricingRules.travelTime">
                  <em />
                </Trans>
              </h4>

              <div className="my-4 flex flex-col gap-4 xl:flex-row xl:gap-8">
                <div className="w-full xl:max-w-[300px]">
                  <SingleCheckbox
                    name={`rules.${index}.travel.chargeLabour`}
                    label={t('forms.companyBranch.tripAndTravel.pricingRules.chargeLabour')}
                    onClick={() => {
                      clearFieldWhenDisabled(
                        `rules.${index}.travel.minLabourTime`,
                        !rules[index]?.travel?.chargeLabour,
                      )
                    }}
                  />
                </div>
                <div className="w-full xl:max-w-[300px]">
                  { rules[index]?.travel?.chargeLabour && (
                    <Input
                      label={t('forms.companyBranch.tripAndTravel.pricingRules.minLabourTime')}
                      name={`rules.${index}.travel.minLabourTime`}
                      size="small"
                      inputProps={{
                        maxLength: 3,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            { t('units.hour_s') }
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) }
                </div>
                <div className="w-full xl:max-w-[300px]" />
              </div>

              <SingleCheckbox
                name={`rules.${index}.chargeTravelKmFee`}
                label={t(
                  useMiles
                    ? 'forms.companyBranch.tripAndTravel.pricingRules.chargePerMileFee'
                    : 'forms.companyBranch.tripAndTravel.pricingRules.chargePerKmFee',
                )}
                onClick={() => {
                  clearFieldWhenDisabled(
                    `rules.${index}.travel.pricePerKm`,
                    !rules[index]?.chargeTravelKmFee,
                  )
                }}
              />

              { rules[index]?.chargeTravelKmFee && (
                <div className="mt-2">
                  <PricesInput
                    companyBranch={companyBranch}
                    prefixName={`rules.${index}.travel.pricePerKm`}
                    endAdornment={t('units.pricePerKm', { currencySymbol, distanceUnit: distanceUnitLabel })}
                    allowDecimal
                  />
                </div>
              ) }

              <h4 className="mb-3 mt-12 font-body2 text-[1.2rem] font-light leading-7">
                <Trans i18nKey="forms.companyBranch.tripAndTravel.pricingRules.transportTime">
                  <em />
                </Trans>
              </h4>

              <div className="my-4 flex flex-col gap-4 xl:flex-row xl:gap-8">
                <div className="w-full xl:max-w-[300px]">
                  <SingleCheckbox
                    name={`rules.${index}.transport.chargeLabour`}
                    label={t('forms.companyBranch.tripAndTravel.pricingRules.chargeLabour')}
                  />
                </div>
              </div>

              <SingleCheckbox
                name={`rules.${index}.chargeTransportKmFee`}
                label={t(
                  useMiles
                    ? 'forms.companyBranch.tripAndTravel.pricingRules.chargePerMileFee'
                    : 'forms.companyBranch.tripAndTravel.pricingRules.chargePerKmFee',
                )}
              />

              { rules[index]?.chargeTransportKmFee && (
                <div className="mt-2">
                  <PricesInput
                    companyBranch={companyBranch}
                    prefixName={`rules.${index}.transport.pricePerKm`}
                    endAdornment={t('units.pricePerKm', { currencySymbol, distanceUnit: distanceUnitLabel })}
                    allowDecimal
                  />
                </div>
              ) }

              <h4 className="mb-3 mt-12 font-body2 text-[1.2rem] font-light leading-7">
                { t('forms.companyBranch.tripAndTravel.pricingRules.fixedTruckFee') }
              </h4>

              <SingleCheckbox
                name={`rules.${index}.chargeFixedTruckFee`}
                label={t('forms.companyBranch.tripAndTravel.pricingRules.chargeFixedTruckFee')}
              />

              { rules[index]?.chargeFixedTruckFee && (
                <div className="mt-2 flex flex-col gap-5">
                  { availableTrucks.map((truck) => {
                    return (
                      <div
                        key={truck}
                      >
                        <div className="text-lg">
                          { t(`trucks.${truck}.title`) }
                        </div>
                        <PricesInput
                          companyBranch={companyBranch}
                          prefixName={`rules.${index}.trucksFee.${truck}`}
                          currency={currency}
                        />
                      </div>
                    )
                  }) }
                </div>
              ) }

            </Paper>
          </div>
        )
      }) }
    </>
  )
}

export default PricingRulesForm
