import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  type CompanyBranchServices,
  Service,
  serviceIcons,
  AdditionalService,
  additionalServiceIcons,
  type CompanyBranch,
  ArrivalWindow,
  Truck,
} from '../../../../modules/companies'
import { Form, CardCheckboxGroup, CardLabel, QuantityCollectionField } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { CompanyBranchServiceToFormData, formatFormOutput } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { type ServicesFormValues } from './ServicesForm.type'

type ServicesFormProps = {
  onSubmit: (payload: CompanyBranchServices) => Promise<any>
  companyBranch?: CompanyBranch
}

const ServicesForm: React.FC<ServicesFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const defaultValues = {
    arrivalWindows: [
      ArrivalWindow.From7to8,
      ArrivalWindow.From8to9,
      ArrivalWindow.From9to10,
      ArrivalWindow.From13to15,
    ],
  }

  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<ServicesFormValues>({
    resolver: yupResolver(schema),
    defaultValues: companyBranch?.services ? CompanyBranchServiceToFormData(companyBranch.services) : defaultValues,
    mode: 'onTouched',
  })

  const formatBeforeSubmit = async (values: ServicesFormValues) => await onSubmit(formatFormOutput(values))

  const companyCountry = companyBranch?.tripAndTravel?.trucksOriginAddress?.country ?? companyBranch?.legal?.companyAddress?.country
  const isAmericanCompany = companyCountry?.toUpperCase() === 'US'

  const getServiceTitle = (service: Service) => {
    const title = t(`services.${service}.title`)
    if (isAmericanCompany) {
      return t(`services.${service}.usTitle`, { defaultValue: title })
    }
    return title
  }
  const getServiceSubTitle = (service: Service) => {
    if (isAmericanCompany) {
      return t(`services.${service}.usSubtitle`, { defaultValue: '' })
    }
    return t(`services.${service}.subtitle`, { defaultValue: '' })
  }

  const servicesOptions = Object.fromEntries(Object.values(Service)
    .filter(service => {
      if (service === Service.UsInterstate) {
        return isAmericanCompany
      }
      return true
    })
    .map(service => ([
      service,
      <CardLabel
        key={service}
        title={getServiceTitle(service)}
        subtitle={getServiceSubTitle(service)}
        Icon={serviceIcons[service]}
      />,
    ])))

  const additionalServicesOptions = Object.fromEntries(Object.values(AdditionalService).map(additionalService => ([
    additionalService,
    <CardLabel
      key={additionalService}
      title={t(`additionalServices.${additionalService}.title`)}
      subtitle={t(`additionalServices.${additionalService}.subtitle`, { defaultValue: '' })}
      Icon={additionalServiceIcons[additionalService]}
    />,
  ])))

  const trucksOptions = Object.values(Truck).map(truck => ({
    value: truck,
    label: t(`trucks.${truck}.title`),
  }))

  const arrivalWindowsOptions = Object.fromEntries(Object.values(ArrivalWindow).map(arrivalWindow => ([
    arrivalWindow,
    <CardLabel
      key={arrivalWindow}
      title={t(`arrivalWindows.${arrivalWindow}.title`)}
    />,
  ])))

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.services.services') }
      </Section>
      <Paper>
        <CardCheckboxGroup
          name="services"
          options={servicesOptions}
        />
      </Paper>

      <Section>
        { t('forms.companyBranch.services.additionalServices') }
      </Section>
      <Paper>
        <CardCheckboxGroup
          name="additionalServices"
          options={additionalServicesOptions}
        />
      </Paper>

      <Section>
        { t('forms.companyBranch.services.howManyTrucks') }
      </Section>

      <Paper>
        <QuantityCollectionField
          name="nbTrucks"
          options={trucksOptions}
        />

      </Paper>

      <Section>
        { t('forms.companyBranch.services.arrivalWindows') }
      </Section>
      <Paper>
        <CardCheckboxGroup
          name="arrivalWindows"
          options={arrivalWindowsOptions}
        />
      </Paper>

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default ServicesForm
