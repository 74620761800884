/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type CompanyBranchPricing } from '.'
import { Season } from '../season'

const isPriceDefined = (value: any) => {
  return value !== undefined && value !== null && value !== ''
}

export const isPriceRowDefined = (value?: CompanyBranchPricing<any>) => {
  if (!value) {
    return false
  }
  return isPriceDefined(value.basePrice) ||
  isPriceDefined(value[Season.Low]) ||
  isPriceDefined(value[Season.High])
}

type PricingConverter = (pricing?: number) => number | undefined

type PricingAsStringOptions = {
  asInteger?: boolean
  converter?: PricingConverter
}
type PricingAsNumberOptions = {
  converter?: PricingConverter
}

/**
 * convert incoming prices (number) as string for forms
 */
export const pricingAsString = (
  pricing?: CompanyBranchPricing<number>,
  {
    asInteger = false,
    converter,
  }: PricingAsStringOptions = {},
): CompanyBranchPricing<string> | undefined => {
  if (!pricing) {
    return
  }

  const getPrice = converter ?? ((price?: number) => price)

  if (asInteger) {
    return {
      basePrice: String(getPrice(pricing.basePrice) ?? ''),
      [Season.Low]: String(getPrice(pricing[Season.Low]) ?? ''),
      [Season.High]: String(getPrice(pricing[Season.High]) ?? ''),
    }
  }
  return {
    basePrice: isPriceDefined(pricing.basePrice) ? String(getPrice(pricing.basePrice)!.toFixed(2)) : '',
    [Season.Low]: isPriceDefined(pricing[Season.Low]) ? String(getPrice(pricing[Season.Low])!.toFixed(2)) : '',
    [Season.High]: isPriceDefined(pricing[Season.High]) ? String(getPrice(pricing[Season.High])!.toFixed(2)) : '',
  }
}

/**
 * convert form prices (string) as number
 */
export const pricingAsNumber = (
  pricing?: CompanyBranchPricing<string>,
  { converter }: PricingAsNumberOptions = {},
): CompanyBranchPricing<number> | undefined => {
  if (!pricing) {
    return
  }
  if (
    !isPriceDefined(pricing.basePrice) &&
    !isPriceDefined(pricing[Season.Low]) &&
    !isPriceDefined(pricing[Season.High])
  ) {
    return
  }

  const getPrice = converter ?? ((price?: number) => price)

  return {
    basePrice: isPriceDefined(pricing.basePrice) ? getPrice(parseFloat(pricing.basePrice!)) : undefined,
    [Season.Low]: isPriceDefined(pricing[Season.Low]) ? getPrice(parseFloat(pricing[Season.Low]!)) : undefined,
    [Season.High]: isPriceDefined(pricing[Season.High]) ? getPrice(parseFloat(pricing[Season.High]!)) : undefined,
  }
}
