import { v4 as uuid } from 'uuid'

import {
  type CompanyBranchPricing,
  type CompanyBranchTripAndTravel,
  pricingAsString,
  pricingAsNumber,
  isPriceRowDefined,
  type CompanyBranchTripAndTravelRuleTrucksFee,
} from '../../../../modules/companies'
import {
  type TripAndTravelFormValues,
  type TripAndTravelFormValuesTrucksFee,
} from './TripAndTravel.type'
import {
  keyValueMapToRecord,
  recordToKeyValueMap,
} from '../../../../common/keyValueMap'
import { convertKmsToMiles, convertMilesToKms } from '../../../../utils/conversion'

const pricingIntAsString = (pricing?: CompanyBranchPricing<number>) => {
  return pricingAsString(pricing, { asInteger: true })
}

const hasFixedTruckFee = (trucksFee?: CompanyBranchTripAndTravelRuleTrucksFee<any>) => {
  if (!trucksFee) {
    return false
  }
  if (Object.keys(trucksFee).length === 0) {
    return false
  }
  return true
}

const convertKmPriceToMilesPrice = (price?: number) => {
  if (!price) {
    return price
  }
  return convertMilesToKms(price, 8)
}

export const CompanyBranchTripAndTravelToFormData = (companyBranchTripAndTravel: CompanyBranchTripAndTravel, useMiles = false): TripAndTravelFormValues => {
  const getDistance = (distance: number) => {
    if (!distance || !useMiles) {
      return distance
    }
    return convertKmsToMiles(distance, 0)
  }

  const priceConverter = useMiles ? convertKmPriceToMilesPrice : undefined

  const formData = {
    trucksOriginAddress: { ...companyBranchTripAndTravel.trucksOriginAddress },
    maxDistance: String(getDistance(companyBranchTripAndTravel.maxDistance)),
    maxPickupRadius: String(getDistance(companyBranchTripAndTravel.maxPickupRadius)),
    rules: companyBranchTripAndTravel.rules.map(rule => ({
      id: uuid(),
      to: String(getDistance(rule.to)),
      travel: {
        chargeLabour: rule.travel.chargeLabour,
        minLabourTime: rule.travel.minLabourTime ? String(rule.travel.minLabourTime) : undefined,
        pricePerKm: pricingAsString(rule.travel.pricePerKm, { converter: priceConverter }),
      },
      transport: {
        chargeLabour: rule.transport.chargeLabour,
        pricePerKm: pricingAsString(rule.transport.pricePerKm, { converter: priceConverter }),
      },
      trucksFee: keyValueMapToRecord(rule.trucksFee, pricingIntAsString),
      chargeTravelKmFee: isPriceRowDefined(rule.travel.pricePerKm),
      chargeTransportKmFee: isPriceRowDefined(rule.transport.pricePerKm),
      chargeFixedTruckFee: hasFixedTruckFee(rule.trucksFee),
    })),
  }
  if ((formData.trucksOriginAddress as any).__typename) {
    delete (formData.trucksOriginAddress as any).__typename
  }

  return formData
}

const filterEmptyTruckValues = (value?: TripAndTravelFormValuesTrucksFee) => {
  if (!value) {
    return value
  }
  return Object.fromEntries(
    Object.entries(value)
      .filter(([_, value]) => Object.keys(value ?? {}).length > 0),
  ) as TripAndTravelFormValuesTrucksFee
}

const convertMilesPriceToKmPrice = (price?: number) => {
  if (!price) {
    return price
  }
  return convertKmsToMiles(price, 8)
}

export const formatFormOutput = (formData: TripAndTravelFormValues, useMiles = false): CompanyBranchTripAndTravel => {
  const getDistance = (distance: number) => {
    if (!distance || !useMiles) {
      return distance
    }
    return convertMilesToKms(distance, 8)
  }

  const priceConverter = useMiles ? convertMilesPriceToKmPrice : undefined

  return {
    trucksOriginAddress: {
      ...formData.trucksOriginAddress,
      apartment: formData.trucksOriginAddress.apartment !== ''
        ? formData.trucksOriginAddress.apartment
        : undefined,
    },
    maxDistance: getDistance(parseInt(formData.maxDistance)),
    maxPickupRadius: getDistance(parseInt(formData.maxPickupRadius)),
    rules: formData.rules.map((rule, index) => {
      const chargeTravelKmFee = !!rule.chargeTravelKmFee
      const chargeTransportKmFee = !!rule.chargeTransportKmFee
      const chargeFixedTruckFee = !!rule.chargeFixedTruckFee

      return {
        to: index === (formData.rules.length - 1)
          ? getDistance(parseInt(formData.maxDistance))
          : getDistance(parseInt(rule.to)),
        travel: {
          chargeLabour: rule.travel.chargeLabour,
          minLabourTime: rule.travel.minLabourTime ? parseFloat(rule.travel.minLabourTime) : undefined,
          pricePerKm: chargeTravelKmFee
            ? pricingAsNumber(rule.travel.pricePerKm, { converter: priceConverter })
            : undefined,
        },
        transport: {
          chargeLabour: rule.transport.chargeLabour,
          pricePerKm: chargeTransportKmFee
            ? pricingAsNumber(rule.transport.pricePerKm, { converter: priceConverter })
            : undefined,
        },
        trucksFee: chargeFixedTruckFee
          ? recordToKeyValueMap(
            filterEmptyTruckValues(rule.trucksFee),
            pricingAsNumber,
          )
          : [],
      }
    }),
  }
}
