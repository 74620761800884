import Language from '../../app/Language.enum'
import { ReactComponent as LogoSmall } from '../../assets/logo-small.svg'
import { useLanguage } from '../../modules/i18n'
import { AuthorSource, type Author } from './MoveNote.types'

type AvatarProps = {
  author: Author
}

const Avatar: React.FC<AvatarProps> = ({
  author,
}) => {
  const language = useLanguage()

  if (author.source === AuthorSource.MovingWaldo) {
    return (
      <div className="flex size-[60px] shrink-0 cursor-default items-center justify-center overflow-hidden rounded-full border-2 border-white bg-neutral-200 shadow-lg">
        <LogoSmall />
      </div>
    )
  }

  if (author.source === AuthorSource.Customer) {
    const initials = [author.user.firstName[0], author.user.lastName[0]].join('')
    return (
      <div className="flex size-[60px] shrink-0 cursor-default items-center justify-center rounded-full border-2 border-white bg-neutral-200 font-body2 text-xl font-bold uppercase text-neutral-500 shadow-lg">
        { initials }
      </div>
    )
  }

  const selectedCompanyName = author.companyBranch.company?.identification?.name?.[language as Language] ?? author.companyBranch.company?.identification?.name?.[Language.En]

  const selectedCompanyLogo = author.companyBranch.company?.logo?.[language as Language] ?? author.companyBranch.company?.logo?.[Language.En]

  if (!selectedCompanyName) {
    return null
  }

  return (
    <div className="flex size-[60px] shrink-0 cursor-default items-center justify-center overflow-hidden rounded-full border border-neutral-300 bg-white font-body2 text-xl font-bold uppercase text-neutral-500 shadow-lg">
      { selectedCompanyLogo
        ? (
          <img
            src={selectedCompanyLogo}
            alt={selectedCompanyName[0]}
          />
          )
        : (
            selectedCompanyName[0]
          ) }
    </div>
  )
}

export default Avatar
