import { useTranslation } from 'react-i18next'

import {
  ChargeCategory,
  chargeCategoryChild,
  ChargeDescription,
} from '../../../../../modules/invoices'
import { type JobForPartner } from '../../../../../modules/jobs'
import { useGetShowDescription, useQuoteItemValueFormatter } from '../../../../../modules/quotes'
import { useMoveState } from '../../../../../modules/moves'
import { useCurrentCompanyBranch } from '../../../../../modules/companies'
import { roundPrice } from '../../../../../utils/money'
import { getRowType, getUnits } from '../../../invoice/ChargesFormContent/ChargeRow/ChargeRow.utils'
import { getSubtotal, getUnitPrice } from '../Charges/utils'
import { ChargeEditability, type ChargeRow } from '../CloseJobForm.types'
import { useGetDefinition } from './definition.hooks'
import { useAvailableCollectionItemsToAdd } from './availableChargesToAdd.hooks'
import { useCollectionItemsPrice } from './companyPricing.hooks'

/**
 * return the parent category of a given charge description
 */
export const useGetDescriptionParentCategory = () => {
  return (description: ChargeDescription) => {
    return (Object.entries(chargeCategoryChild).find(([, charges]) => {
      return charges.includes(description)
    })?.[0] ?? ChargeCategory.additionalCharges) as ChargeCategory
  }
}

/**
 * split category and description
 */
export const useGetCategoryAndDescription = () => {
  return (categoryAndDescription: string) => {
    const [category, description] = categoryAndDescription.split('.') as [ChargeCategory, ChargeDescription]
    return { category, description }
  }
}

/**
 * use collection state to easily render them
 */
export const useGetChargeCollectionState = () => {
  const getDefinition = useGetDefinition()
  const getCategoryAndDescription = useGetCategoryAndDescription()

  return (charge: ChargeRow, charges: ChargeRow[]) => {
    const { description } = getCategoryAndDescription(charge.categoryAndDescription)
    const { collection } = getDefinition(description)
    const isFirstOfKind = charges
      .find(({ categoryAndDescription }) => categoryAndDescription === charge.categoryAndDescription) === charge

    const collectionItems = collection && isFirstOfKind
      ? charges
        .map<[number, ChargeRow]>((value, index) => [index, value])
        .filter(([, value]) => value.categoryAndDescription === charge.categoryAndDescription)
      : []

    return {
      isCollection: collection,
      collectionItems,
    }
  }
}

/**
 * format the title of the charge
 */
const useGetChargeTitle = () => {
  const { t } = useTranslation()
  const getCategoryAndDescription = useGetCategoryAndDescription()
  const formatQuoteItemValue = useQuoteItemValueFormatter()
  const getShowDescription = useGetShowDescription()

  return (charge: ChargeRow, specificItemName = false) => {
    const description = getCategoryAndDescription(charge.categoryAndDescription).description

    const chargeDescription = t(`quotes.pricing.description.${description}`)
    const additionalDetail = formatQuoteItemValue(description, charge.item)

    if (specificItemName && additionalDetail) {
      return additionalDetail
    }

    const showDescription = getShowDescription(description)
    if (showDescription && additionalDetail) {
      return `${chargeDescription} (${additionalDetail})`
    }
    return chargeDescription
  }
}

/**
 * get the state of a given charge row
 */
export const useGetChargeState = () => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const getCategoryAndDescription = useGetCategoryAndDescription()
  const getDefinition = useGetDefinition()
  const getChargeTitle = useGetChargeTitle()
  const getMoveState = useMoveState().forMove

  return (charge: ChargeRow, job: JobForPartner) => {
    const description = getCategoryAndDescription(charge.categoryAndDescription).description
    const definiton = getDefinition(description)
    const { isUsingDoubleDriveTime } = getMoveState(job.move)

    const originCountry = 'country' in job.move.origin.address ? job.move.origin.address.country : undefined

    const isDuplicated = typeof charge.chargeRefIndex === 'undefined'
      ? false
      : job.quote.details.findIndex(row => row.description === description) !== charge.chargeRefIndex

    const isCollection = definiton.collection

    const quantityEditable = definiton.editable !== ChargeEditability.NotEditable
    const unitPriceEditable = definiton.unitPriceEditable

    const currency = job.quote.subtotal.currency

    const unitPrice = getUnitPrice(charge.unitPrice, currency)
    const subtotal = getSubtotal(unitPrice, charge.quantity)

    const chargeTitle = getChargeTitle(charge)
    const individualItemTitle = getChargeTitle(charge, true)
    const defaultHelpText = t(`forms.companyBranch.closeJob.helpText.${description}`, { defaultValue: '' })
    const helpText = isUsingDoubleDriveTime
      ? t(`forms.companyBranch.closeJob.helpTextDoubleDriveTime.${description}`, { defaultValue: defaultHelpText })
      : defaultHelpText

    const rowType = getRowType(description)
    const units = getUnits(rowType, t, originCountry)
    const unitAdornment = units.length === 1 ? units[0].label : undefined

    const minTime = description === ChargeDescription.movingLabour
      ? currentCompanyBranch?.labour?.minLabourTime
      : undefined

    const doubleDrive = isUsingDoubleDriveTime && description === ChargeDescription.transportLabour

    const itemExistsInQuote = !!job.quote.details.find(c => c.description === description && c.item === charge.item)

    return {
      quantityEditable: quantityEditable && (!isDuplicated || isCollection),
      unitPriceEditable: unitPriceEditable && (!isDuplicated || isCollection),
      unitPriceDisabled: itemExistsInQuote,
      chargeTitle,
      individualItemTitle,
      helpText,
      unitPrice,
      subtotal,
      unitAdornment,
      minTime,
      doubleDrive,
    }
  }
}

export const useChargeState = (charge: ChargeRow, job: JobForPartner) => {
  return useGetChargeState()(charge, job)
}

/**
 * get the state of a given collection charge row (parent row that holds the collection)
 */
export const useCollectionChargeState = (
  job: JobForPartner,
  charge: ChargeRow,
  items: ChargeRow[],
) => {
  const { t } = useTranslation()
  const description = useGetCategoryAndDescription()(charge.categoryAndDescription).description

  const definiton = useGetDefinition()(description)
  const quantityEditable = definiton.editable !== ChargeEditability.NotEditable

  const chargeTitle = t(`quotes.pricing.description.${description}`)

  const itemsIncluded = items.map(i => i.item) as string[]

  const itemsPrice = useCollectionItemsPrice(description, job)

  const availableItemsToAdd = useAvailableCollectionItemsToAdd(
    description,
    itemsPrice,
    itemsIncluded,
  )

  const subtotal = items.reduce((acc, curr) => {
    const rowSubtotal = parseFloat(curr.quantity) * parseFloat(curr.unitPrice)
    if (!isNaN(rowSubtotal)) {
      return roundPrice(acc + rowSubtotal)
    }
    return acc
  }, 0)
  const currency = job.quote.subtotal.currency

  return {
    quantityEditable,
    description,
    chargeTitle,
    availableItemsToAdd,
    itemsPrice,
    subtotal: { price: subtotal ?? 0, currency },
  }
}
