import { invoiceCharge } from '../../invoices/core/invoices.queries'

export const resolvedRequestFields = `
stops {
  description
  stopDuration
}
moveLeg {
  origin {
    type
    coordinates
  }
  destination {
    type
    coordinates
  }
  distanceMeters
  durationSeconds
  durationInTrafficSeconds
}
originTimezone
timeline {
  originArrivalTime
  finishPackingTime
  finishLoadingTruckTime
  originDepartureTime
  stops
  destinationArrivalTime
  finishUnloadingTruckTime
  destinationDepartureTime
}
moveDurationSeconds
requiredMovingServices
`

export const commonQuotesFields = `
id
createdAt
date
forAlternativeDate
straightLineDistance
resolvedNbMen
resolvedNbTrucks {
  key
  value
}
totalDuration
totalDistance
details {
  ${invoiceCharge}
}
subtotal {
  price
  currency
}
constraints
`

export const quotesFieldsForPartner = `
${commonQuotesFields}
totalDuration
totalDistance
`
